const sidebarNav = [
    
    {
        link: '/main',
        section: '/main',
        icon: <i className='bx bx-home-alt'></i>,
        text: 'Home'
    },
    {
        link: '/main/postreceipt',
        section: '/postreceipt',
        icon: <i className='bx bx-receipt' ></i>,
        text: 'Receipt'
    },
    {
        link: '/main/postletter',
        section: '/postletter',
        icon: <i className='bx bx-envelope'></i>,
        text: 'Letter'
    },
    {
        link: '/main/roles',
        section: '/roles',
        icon: <i className='bx bx-user-plus'></i>,
        text: 'Roles'
    },
    {
        link: '/main/recurrents',
        section: '/recurrents',
        icon: <i className='bx bx-repeat'></i>,
        text: 'Recurrent'
    },
    {
        link: '/main/report',
        section: '/report',
        icon: <i className='bx bxs-report'></i>,
        text: 'Full Report'
    },
]

export default sidebarNav