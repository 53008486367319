import React from 'react'
import { FormLetter } from '../components/form/FormLetter'

const AddLetter = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop:"45px", paddingBottom:"-20px" }}>
        <FormLetter/>
    </div>
  )
}

export default AddLetter