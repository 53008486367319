import React from 'react';
import './main-layout.scss';
import { Outlet } from 'react-router-dom';
import TopNav from '../components/topnav/topnav';
import Sidebar from '../components/sidenav/sidebar'



const MainLayout = () => {
    return (
        <>
           
            <Sidebar/>
            <div className="main">
                <div className="main__content">
                    <TopNav />
                    <Outlet />
                </div>
            </div>
        </>
    )
}

export default MainLayout
