import React, { useContext } from 'react';
import { useState, useEffect } from 'react';
import BoxForm from '../box/BoxForm';
import axios from 'axios';

import { useParams, useNavigate, Navigate } from 'react-router-dom';

import './StylesScss/form.scss';
import toast, { Toaster } from 'react-hot-toast';

import { LoginContext } from "../../App";
import { baseUrl } from '../../api/shared';




const EditPage = () => {


    const navigate = useNavigate();

    const [values, setValues] = useState({
        receivedFrom: "Ebet Kenya LTD",
        amount: "",
        description: "",
        payee: "",
        paymentType: "",
        approvedBy: "",
        paidBy: "",
        userId: "",
        userProcessor: "",
        expenseType: ""
    });


    const [selectedFile, setSelectedFile] = useState(null);

    const { id } = useParams();


    useEffect(() => {
        getReceipt(id);
    }, [id]);


    async function getReceipt(receiptId) {
        try {
            const response = await axios.get(`${baseUrl}/api/payments/${receiptId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                }
            });
            const { data } = response;
            setValues({

                receivedFrom: data.receivedFrom,
                amount: data.amount,
                description: data.description,
                payee: data.payee,
                paymentType: data.paymentType,
                approvedBy: data.approvedBy,
                paidBy: data.paidBy,
                receiptPath: data.receiptPath,
                userId: data.userId,
                userProcessor: data.userProcessor,
                expenseType: data.expenseType
            });
        } catch (error) {
            console.error(error);
        }
    }

    const handleInputChange = e => {
        const { name, value } = e.target;
        setValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    }


    const allItems = [
        'CSR',
        'Furniture',
        'Show',
        'Electronics',
        'Stationary',
        'Breakfast',
        'Uniform',
        'Bus Fuel',
        'Bus Service',
        'Internet',
        'Electricity',
        'School Fee',
    ];


    const handleFileChange = e => {
        const receiptFile = e.target.files[0];
        setSelectedFile(receiptFile);
    }


    const handleUpdate = async (formData) => {
        try {
            // Perform the update operation
            await axios.put(`${baseUrl}/api/payments/${id}`, formData, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                },
            });
    
            // Trigger success toast for update
            toast.success('Receipt details updated successfully', {
                duration: 5000,
                icon: '✅',
            });
        } catch (error) {
            console.error( "An error occured:", {duration: 4000, icon: '🚩' });
        }
    };

    
    

    
    const handleDelete = async () => {
        try {
            // Perform the delete operation
            await axios.delete(`${baseUrl}/api/Payments/${id}`, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'multipart/form-data',
                },
            });
    
            // Redirect to the home page after successful delete
            navigate('/main');
    
            // Display success toast
            toast.success('Form deleted successfully', {
                duration: 5000,
                icon: '🗑️',
            });
        } catch (error) {
            // Extract the error message
            const errorMessage = error.response?.data?.message || 'An error occurred';
    
            // Display error toast
            toast.error(errorMessage, { duration: 4000, icon: '🚩' });
        }
    };
    
    const [loggedIn] = useContext(LoginContext);

    if (!loggedIn) {
        // Redirect to login if not logged in
        return <Navigate to="/" />;
    }


    const handleSubmit = async e => {
        e.preventDefault();
        try {
            const formData = new FormData();

            formData.append('receivedFrom', values.receivedFrom);
            formData.append('paymentType', values.paymentType);
            formData.append('amount', values.amount);
            formData.append('description', values.description);
            formData.append('payee', values.payee);
            formData.append('approvedBy', values.approvedBy);
            formData.append('paidBy', values.paidBy);
            formData.append('userId', values.userId);
            formData.append('userProcessor', values.userProcessor);
            formData.append('receiptPath', values.receiptPath);
            formData.append('expenseType', values.expenseType);

            if (selectedFile) {
                formData.append('receiptFile', selectedFile);
            } else {
                formData.append('receiptPath', values.receiptPath);
            }

            await handleUpdate(formData);

        } catch (error) {
            console.log('An error occurred:', error);
        }
    }

    return (
        <>
            <Toaster position='top-right' />
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: "45px", paddingBottom: "-20px" }}>
                <BoxForm >
                    <div className="expense-form">
                        <form className="expense-form__form" onSubmit={handleSubmit} method="post">
                            <div className="expense-form__section">
                                <h4>SOURCE OF FINANCE</h4>
                                <div className="expense-form__field">
                                    <input
                                        type="text"
                                        name="receivedFrom"
                                        value={values.receivedFrom}
                                        readOnly
                                        className="expense-form__input"
                                        placeholder="Received from:"
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className='check'>
                                <label className="l-radio col-md-2">
                                    <input className='pesa' type="radio" id="f-option" tabIndex="1"
                                        name="paymentType"
                                        value="Mpesa"
                                        checked={values.paymentType === "Mpesa"}
                                        onChange={handleInputChange}
                                    />
                                    <span>Mpesa</span>
                                </label>
                                <label className="l-radio col-md-2">
                                    <input className='pesa' type="radio" id="s-option" tabIndex="2"
                                        name="paymentType"
                                        value="Cash"
                                        checked={values.paymentType === "Cash"}
                                        onChange={handleInputChange}
                                    />
                                    <span>Cash</span>
                                </label>
                                <label className="l-radio col-md-2">
                                    <input className='pesa' type="radio" id="d-option" tabIndex="3"
                                        name="paymentType"
                                        value="Accounts"
                                        checked={values.paymentType === "Accounts"}
                                        onChange={handleInputChange}
                                    />
                                    <span>Acc</span>
                                </label>
                                <label className="l-radio col-md-2">
                                    <input className='pesa' type="radio" id="d-option" tabIndex="4"
                                        name="paymentType"
                                        value="School Fee"
                                        checked={values.paymentType === "School Fee"}
                                        onChange={handleInputChange}
                                    />
                                    <span>Fee</span>
                                </label>

                            </div>

                                <div className="expense-form__input-group" style={{ display: "flex", flexDirection: "row" }}>

                                    <select className=" col-6"
                                        style={{ height: "45px" }}
                                        name='expenseType'
                                        value={values.expenseType}
                                        onChange={handleInputChange}

                                    >
                                        {allItems
                                            .map((item, index) => (
                                                <option key={index} className="t-dropdown-item">
                                                    {item}
                                                </option>
                                            ))}
                                    </select>

                                    <input
                                        type="text"
                                        className="expense-form__input col-6"
                                        name='expenseType'
                                        value={values.expenseType}
                                        onChange={handleInputChange}
                                        placeholder="Expense Type"
                                    />


                                </div>

                                <div className="expense-form__input-group">
                                    <input
                                        type="number"
                                        name="amount"
                                        value={values.amount}
                                        className="expense-form__input"
                                        placeholder="Amount"
                                        onChange={handleInputChange}
                                    />
                                    <span className="expense-form__icon">
                                        <i className="bx bx-cash"></i>
                                    </span>
                                </div>

                                <div className="expense-form__field" style={{ display: "none" }} >
                                    <input
                                        type="text"
                                        name="userProcessor"
                                        value={values.userProcessor}
                                        readOnly
                                        className="expense-form__input"
                                        onChange={handleInputChange}
                                    />

                                    <input
                                        type="text"
                                        readOnly
                                        name="userId"
                                        className="expense-form__input"
                                        value={values.userId}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="expense-form__input-group filePath">
                                    <input
                                        type="text"
                                        name="receiptPath"
                                        value={values.receiptPath}
                                        className="expense-form__input"
                                        placeholder="receiptPath"
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className="expense-form__section">
                                <h4>EXPENSE</h4>
                                <div className="row">
                                    <div className="expense-form__field col-6" >
                                        <textarea
                                            type="text"
                                            name="description"
                                            value={values.description}
                                            className="expense-form__input"
                                            placeholder="Description:"
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="expense-form__field col-6">
                                        <textarea
                                            type="text"
                                            name="payee"
                                            value={values.payee}
                                            className="expense-form__input"
                                            placeholder="Payee:"
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>

                            </div>
                            <div className="expense-form__section row">
                                <div className="expense-form__field col-6">
                                    <textarea
                                        type="text"
                                        name="approvedBy"
                                        value={values.approvedBy}
                                        className="expense-form__input"
                                        placeholder="Approved By:"
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="expense-form__field col-6">
                                    <textarea
                                        type="text"
                                        name="paidBy"
                                        value={values.paidBy}
                                        className="expense-form__input"
                                        placeholder="Paid By:"
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>

                            <h4>Receipt</h4>
                            <input
                                id="file-uploader"
                                type="file"
                                name="receiptFile"
                                accept="*"
                                className="expense-form__filePdf col-md-8 col-sm-12"
                                placeholder="Upload Receipt"
                                onChange={handleFileChange}

                            />
                            <div className="row">
                                <div className="col-6">
                                    <dl className="details">
                                        <div>
                                            <button className="button" type='submit'> 
                                                Update
                                            </button>
                                        </div>
                                    </dl>
                                </div>
                                <div className="col-6">
                                    <dl className="details">
                                        <div>
                                            <button type="button" className="button" onClick={handleDelete}>
                                                Delete
                                            </button>
                                        </div>
                                    </dl>
                                </div>
                            </div>

                        </form>
                    </div>

                </BoxForm>

            </div>
        </>
    )
}

export default EditPage
