import React, { useState } from 'react';
import './account.scss';
//import BoxForm from '../box/BoxForm';
import AuthForm from './AuthForm';
import Register from './register';
import Login from './login';

const Authentication = () => {
  const [isRegisterActive, setIsRegisterActive] = useState(false);

  const handleRegisterClick = () => {
    setIsRegisterActive(true);
  }

  const handleLoginClick = () => {
    setIsRegisterActive(false);
  }





  return (
    <div className="resize col-6 col-md-6">
      <AuthForm>
        <div className={`container ${isRegisterActive ? 'active' : ''}`} id='container'>
          <div className={`form-container ${isRegisterActive ? 'hidden' : 'sign-in'}`}>
              <Login />
          </div>
          <div className={`form-container sign-up ${isRegisterActive ? 'sign-up-active' : 'hidden'}`}>
              <Register />
          </div>
          <div className="toggle-container">
            <div className="toggle">
              <div className={`toggle-panel toggle-left ${isRegisterActive ? 'hidden' : ''}`}>
                <h1>Hello, Lotto</h1>
                <p>Register with your personal details to use site features</p>
                <button className='hidden' onClick={handleLoginClick}>Sign In</button>
              </div>
              <div className={`toggle-panel toggle-right ${isRegisterActive ? '' : 'hidden'}`}>
                <h1>Welcome Back!</h1>
                <p>Enter your personal details to use site features</p>
                <button className='hidden' onClick={handleRegisterClick}>Sign Up</button>
              </div>
            </div>
          </div>
        </div>
      </AuthForm>
    </div>

  );
};

export default Authentication;
