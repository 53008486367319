import React, { useState, useEffect, useContext } from 'react'
import './StylesScss/single-receipt.scss'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { LoginContext } from '../../App';
import { baseUrl } from '../../api/shared';

const SingleLetter = () => {


  const[loggedIn, setLoggedIn] = useContext(LoginContext);

  const [value, setValue] = useState(null);
  const [loading, setLoading] = useState(true)

  const handlePrint = () => {
    window.print();
  }

  const { id } = useParams();

  useEffect(() => {
    getReceipt(id);
  }, [id]);


  async function getReceipt(letterId) {
    try {
      const response = await axios.get( baseUrl + `/api/letters/${letterId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        }
      });
      const { data } = response;
      setLoading(false);
      setValue(data);
      //console.log(data);
    } catch (error) {
      console.error(error);
      // Handle the error here, such as displaying an error message to the user.
    }
  }

  if (!value) {
    return <p>Loading....</p>;
  }

  return (
    <div className="container"   >
      {loading ? (<div>Loading.....</div>) : (
        <div className=' col-md-12 col-12 col-sm-12 receiptView' onClick={handlePrint} >

          <iframe src={`${baseUrl}${value.letterFilePath}`}
            title='Letter'
            style={{ border: 'none', width: "530px", height: "810px", overflow: "hidden" }}
          />

        </div>
      )}

    </div>
  )
}

export default SingleLetter