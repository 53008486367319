import React from 'react'
import './boxform.scss'

const BoxForm = props => {
    const className = {
        boxForm: 'boxForm',
        
    }

    const id ={
        id:"printData"
    }

    return (
        <div className={Object.values(className).join(' ')}  id={Object.values(id).join(' ')} >
            {props.children}
        </div>
    )
}

export default BoxForm
