import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import './StylesScss/list-cards.scss';
import { Link } from 'react-router-dom';
import { LoginContext } from '../../App';
import { baseUrl } from '../../api/shared';

const RecurrentList = () => {


  const [loggedIn, setLoggedIn] = useContext(LoginContext);
  
  const [values, setValues] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [pageNumber, setPageNumber] = useState(1);
  const pageSize = 12;

  const [searchQuery, setSearchQuery] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [filteredValues, setFilteredValues] = useState([]);

  useEffect(() => {
    getDataList();
  }, [pageNumber, searchQuery, startDate, endDate]);

  const getDataList = () => {
    let apiUrl = baseUrl + `/api/letters/recurrent?pageNumber=${pageNumber}&pageSize=${pageSize}`;

    // Include search parameters if they are set
    if (startDate || endDate) {
      const searchParams = new URLSearchParams();
      if (searchQuery) searchParams.append('searchQuery', searchQuery);
      if (startDate) searchParams.append('startDate', startDate);
      if (endDate) searchParams.append('endDate', endDate);

      apiUrl += `&${searchParams.toString()}`;
    }

    axios.get(apiUrl, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }
    })
      .then(response => {
        setFilteredValues(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      })
      .finally(() => {
        setIsLoading(false); // Stop loading
      });
  }

  useEffect(() => {
    // Filter data based on selectedOption, startDate, endDate
    const filteredData = values.filter(value => {
      return (
        value.date >= startDate &&
        value.date <= endDate
      );
    });
    setFilteredValues(filteredData);
  }, [startDate, endDate, values]);

  const handlePreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const handleNextPage = () => {
    setPageNumber(pageNumber + 1);
  };

  return (
    <>

      <div className="row" style={{ padding: "7px" }}>

        

        <div className="col-6 col-md-6 col-lg-6 searchDates">

          <label style={{ marginRight: "20px" }}>Start</label>
          <input
            type="date"
            style={{ width: "150px", borderRadius: "10px", height: "30px", padding: "5px" }}
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
          <label style={{ marginRight: "20px", marginLeft: "5px" }}>End</label>
          <input
            type='date'
            style={{ width: "150px", borderRadius: "10px", height: "30px", padding: "5px" }}
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
      </div>


      <div className="container-table">
        <ul className="responsive-table">
          <li className="table-header">
            <div className="col col-3 text-header">Description</div>
            <div className="col col-3 text-header">Uploaded By</div>
            <div className="col col-3 text-header"></div>
           
            <div className="col col-3 text-header">
              <Link to="/main/recurrent">
                 <i className='bx bx-add-to-queue' style={{float:"right"}}></i>
              </Link>
             
            </div>
          </li>
          <>
            {isLoading ? (
              <div>Loading...</div>
            ) : (
              filteredValues.map((value, index) => (
                <li className="table-row" key={value.id}>
                  <div className="col col-3" data-label="Expense">{value.description}</div>
                  <div className="col col-3" data-label="Paid By">{value.userName}</div>
                  <Link className="col col-3" to={`/main/recurrentExpense/${value.id}`}>
                    View
                  </Link>
                  <Link className="col col-3" to={`/main/editRecurrent/${value.id}`}>
                    Edit
                  </Link>
                </li>
              ))
            )}
            <li style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }} >
              <button style={{ margin: "5px", width: "100px", height: "40px", borderRadius: "5px", background: "#8624DB", color: "white" }} onClick={handlePreviousPage}>Pervious</button>
              <button style={{ margin: "5px", width: "100px", height: "40px", borderRadius: "5px", background: "#8624DB", color: "white" }} onClick={handleNextPage} >Next</button>
            </li>

          </>
        </ul>
      </div>

    </>
  )
}

export default RecurrentList