import axios from 'axios';
import React, { useEffect, useState } from 'react';
import './StylesScss/reports.scss';
import { baseUrl } from '../../api/shared';
import { Link } from 'react-router-dom';

const ReportGeneration = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [paymentData, setPaymentData] = useState([]);
    const [error, setError] = useState(null);


    useEffect(() => {
        // Fetch data when both start and end dates are provided
        if (startDate && endDate) {
            getData();
        }
    }, [startDate, endDate]);

    const getData = async () => {
        try {
            const response = await axios.get(
                `${baseUrl}/api/payments/totalPayment/?startDate=${startDate}&endDate=${endDate}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    }
                }

            );
            setPaymentData(response.data);
            setIsLoading(false);
            setError(null);
        } catch (error) {
            setError('Error fetching data');
        }
    };

    const handleStartDateChange = (e) => {
        setStartDate(e.target.value);
    };

    const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
    };


    let totalAmount = 0;

    const handlePrint = () => {
        window.print();
    }

    return (
        <>

            <div style={{justifyContent:"space-evenly"}}>
                <div className="row" style={{ paddingTop: "15px" }} >
                    <div className="col-md-4 col-4 col-lg-4 dates-hide">
                        <div className="report">
                            <div className="dates-fields" style={{ padding: "7px" }}>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent:"space-between", width:"350px" }}>
                                    <label style={{ marginRight: "20px" }}>Start</label>
                                    <input
                                        type="date"
                                        style={{   borderRadius: "10px", height: "30px", padding: "5px" }}
                                        value={startDate}
                                        onChange={handleStartDateChange}
                                    />
                                    <label style={{ marginRight: "20px", marginLeft: "5px" }}>End</label>
                                    <input
                                        type="date"
                                        style={{  borderRadius: "10px", height: "30px", padding: "5px" }}
                                        value={endDate}
                                        onChange={handleEndDateChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-4 col-lg-4 single-report" style={{ width:"200px", marginLeft:"210px" , padding:"4px"}}>
                        <Link to="/main/singleReport">
                            Report
                            <i className='bx bxs-report'></i>
                        </Link>
                    </div>
                    <div className="col-md-4 col-4 col-lg-4 print-btn">
                        <div className="printer" style={{ float: "right" }} onClick={handlePrint}>
                            <div className="paper">
                                <svg viewBox="0 0 8 8" className="svg">
                                    <path fill="#0077FF" d="M6.28951 1.3867C6.91292 0.809799 7.00842 0 7.00842 0C7.00842 0 6.45246 0.602112 5.54326 0.602112C4.82505 0.602112 4.27655 0.596787 4.07703 0.595012L3.99644 0.594302C1.94904 0.594302 0.290039 2.25224 0.290039 4.29715C0.290039 6.34206 1.94975 8 3.99644 8C6.04312 8 7.70284 6.34206 7.70284 4.29715C7.70347 3.73662 7.57647 3.18331 7.33147 2.67916C7.08647 2.17502 6.7299 1.73327 6.2888 1.38741L6.28951 1.3867ZM3.99679 6.532C2.76133 6.532 1.75875 5.53084 1.75875 4.29609C1.75875 3.06133 2.76097 2.06018 3.99679 2.06018C4.06423 2.06014 4.13163 2.06311 4.1988 2.06905L4.2414 2.07367C4.25028 2.07438 4.26057 2.0758 4.27406 2.07651C4.81533 2.1436 5.31342 2.40616 5.67465 2.81479C6.03589 3.22342 6.23536 3.74997 6.23554 4.29538C6.23554 5.53084 5.23439 6.532 3.9975 6.532H3.99679Z"></path>
                                    <path fill="#0055BB" d="M6.756 1.82386C6.19293 2.09 5.58359 2.24445 4.96173 2.27864C4.74513 2.17453 4.51296 2.10653 4.27441 2.07734C4.4718 2.09225 5.16906 2.07947 5.90892 1.66374C6.04642 1.58672 6.1743 1.49364 6.28986 1.38647C6.45751 1.51849 6.61346 1.6647 6.756 1.8235V1.82386Z"></path>
                                </svg>
                            </div>
                            <div className="dot"></div>
                            <div className="output">
                                <div className="paper-out"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {error && <div style={{ color: 'red' }}>{error}</div>}
            <div className="report receipt-toprint">
                <table className="col-md-9 col-lg-8 table-fill " >
                    <thead>
                        <tr>
                            <th className="text-left col-md-6">Expense</th>
                            <th className="text-left col-md-6">Total Amount</th>
                        </tr>
                    </thead>
                    <tbody className="table-hover">
                        {isLoading ? (
                            <tr>
                                <td className="text-left col-md-6">Loading....</td>
                                <td className="text-left col-md-6" >Loading....</td>
                            </tr>
                        ) : (
                            paymentData.map((value, index) => {
                                totalAmount += value.amount; // Summing up the amount
                                return (
                                    <tr key={index}>
                                        <td className="text-left col-md-6">{value.expenseType}</td>
                                        <td className="text-left col-md-6">{value.amount}</td>
                                    </tr>
                                );
                            })
                        )}

                        <tr>
                            <td className="text-left col-md-6">Total</td>
                            <td className="text-left col-md-6">{totalAmount}</td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </>
    );
}

export default ReportGeneration;
