import React from "react";
import './boxletter.scss';

const BoxLetter = props => {
    const className = {
        boxLetter: 'boxLetter', 
    }

    return (
        <div className={Object.values(className).join(' ')} >
            {props.children}
        </div>
    )
}

export default BoxLetter