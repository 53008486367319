import React, { useEffect, useState } from 'react'
import './sidebar.scss'
import { Link, useLocation } from 'react-router-dom'
import { images } from '../../constants'
import sidebarNav from '../../configs/sidebarNav'
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { baseUrl } from '../../api/shared'
import axios from 'axios'

const Sidebar = () => {
    const [activeIndex, setActiveIndex] = useState(0)
    const location = useLocation()
    const [userRoles, setUserRoles] = useState([]);
    const userId = localStorage.getItem('userId');
    

    useEffect(() => {
        const curPath = window.location.pathname.split('/main')[1]
        const activeItem = sidebarNav.findIndex(item => item.section === curPath)

        setActiveIndex(curPath.length === 0 ? 0 : activeItem)
    }, [location])

    useEffect(() => {
        sessionStorage.clear();
    }, []);

    const usenavigate = useNavigate();


    useEffect(() => {
        if (userId) {
            // Fetch user roles using Axios
            axios.get(baseUrl + `/api/UserRoles/${userId}`)
                .then((response) => {
                    // Check if the response contains data and has the expected structure
                    if (response.data && response.data.roles) {
                        const currentUserRoles = response.data.roles;
                        setUserRoles(currentUserRoles);
                    } else {
                        console.error('Error fetching user roles: Invalid response structure');
                    }
                })
                .catch((error) => {
                    console.error('Error fetching user roles:', error);
                });
        }
    }, [userId]);
    

    const closeSidebar = () => {
        document.querySelector('.main__content').style.transform = 'scale(1) translateX(0)'
        setTimeout(() => {
            document.body.classList.remove('sidebar-open')
            document.querySelector('.main__content').style = ''
        }, 500);
    }



    const logOut = (e) => {
        e.preventDefault();
        localStorage.removeItem('name');
        localStorage.removeItem('token');
        localStorage.removeItem('username');
        localStorage.removeItem('statusCode');
        localStorage.removeItem('refreshToken');
        toast.success('logged Out')
        usenavigate('/');
    };

    return (
        <>
            <Toaster position='top-right' />
            <div className='sidebar'>
                <div className="sidebar__logo">
                    <img src={images.ebet} alt="" />
                    <div className="sidebar-close" onClick={closeSidebar}>
                        <i className='bx bx-x'></i>
                    </div>
                </div>
                <div className="sidebar__menu">
                    {sidebarNav.map((nav, index) => (
                        // Check if the current item is the "Roles" tab
                        nav.text === 'Roles' && !userRoles.includes('Super') ? null : (
                            
                            <Link
                                to={nav.link}
                                key={`nav-${index}`}
                                className={`sidebar__menu__item ${activeIndex === index && 'active'}`}
                                onClick={closeSidebar}
                            >
                                <div className="sidebar__menu__item__icon">{nav.icon}</div>
                                <div className="sidebar__menu__item__txt">{nav.text}</div>
                            </Link>
                        )
                    ))}

                    <div className="sidebar__menu__item">

                        <div className="sidebar__menu__item__icon">
                            <i className='bx bx-log-out'></i>
                        </div>
                        <Link to="/" onClick={logOut}>
                            <div className="sidebar__menu__item__txt">
                                Logout
                            </div>
                        </Link>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Sidebar
