import React from 'react';
import SingleReceipt from "../components/form/SingleReceipt";
const ReceiptSingle = () => {
  return (
    <div >
        <SingleReceipt/>
    </div>
  )
}

export default ReceiptSingle