import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import './StylesScss/single-receipt.scss'
import { useParams } from 'react-router-dom';
import { images } from '../../constants'
import { LoginContext } from '../../App';
import BoxPrint from "../box/BoxPrint";
import { baseUrl } from '../../api/shared';

const SingleReceipt = () => {

  const [loggedIn, setLoggedIn] = useContext(LoginContext);

  const [value, setValue] = useState(null);
  const [loading, setLoading] = useState(true)

  const handlePrint = () => {
    window.print();
  }

  const { id } = useParams();

  useEffect(() => {
    getReceipt(id);
  }, [id]);




  async function getReceipt(receiptId) {
    try {
      const response = await axios.get( baseUrl + `/api/payments/${receiptId}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        }
      });
      const { data } = response;
      setLoading(false);
      setValue(data);
      //console.log(data);
    } catch (error) {
      console.error(error);
      // Handle the error here, such as displaying an error message to the user.
    }
  }

  if (!value) {
    return <p>Loading....</p>;
  }

  const rawDate = (value.creationDate)

  const formattedDate = new Date(rawDate);

  const options = { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' }

  const formattedDateString = new Intl.DateTimeFormat('en-US', options).format(formattedDate);

  return (
    <>
      <div className="container-print">
        <div className="printer" onClick={handlePrint} >
          <div className="paper">
            <svg viewBox="0 0 8 8" className="svg">
              <path fill="#0077FF" d="M6.28951 1.3867C6.91292 0.809799 7.00842 0 7.00842 0C7.00842 0 6.45246 0.602112 5.54326 0.602112C4.82505 0.602112 4.27655 0.596787 4.07703 0.595012L3.99644 0.594302C1.94904 0.594302 0.290039 2.25224 0.290039 4.29715C0.290039 6.34206 1.94975 8 3.99644 8C6.04312 8 7.70284 6.34206 7.70284 4.29715C7.70347 3.73662 7.57647 3.18331 7.33147 2.67916C7.08647 2.17502 6.7299 1.73327 6.2888 1.38741L6.28951 1.3867ZM3.99679 6.532C2.76133 6.532 1.75875 5.53084 1.75875 4.29609C1.75875 3.06133 2.76097 2.06018 3.99679 2.06018C4.06423 2.06014 4.13163 2.06311 4.1988 2.06905L4.2414 2.07367C4.25028 2.07438 4.26057 2.0758 4.27406 2.07651C4.81533 2.1436 5.31342 2.40616 5.67465 2.81479C6.03589 3.22342 6.23536 3.74997 6.23554 4.29538C6.23554 5.53084 5.23439 6.532 3.9975 6.532H3.99679Z"></path>
              <path fill="#0055BB" d="M6.756 1.82386C6.19293 2.09 5.58359 2.24445 4.96173 2.27864C4.74513 2.17453 4.51296 2.10653 4.27441 2.07734C4.4718 2.09225 5.16906 2.07947 5.90892 1.66374C6.04642 1.58672 6.1743 1.49364 6.28986 1.38647C6.45751 1.51849 6.61346 1.6647 6.756 1.8235V1.82386Z"></path>
            </svg>
          </div>
          <div className="dot"></div>
          <div className="output">
            <div className="paper-out"></div>
          </div>
        </div>
      </div>

      <div className='container '>

        {loading ? <p>loading....</p> :

          <div className=" data-toprint data-row row " >
            <div className='col-md-6 col-lg-6'>
              <BoxPrint>
                <div className="col-md-12  col-sm-12" >
                  <div className="row">
                    <div className="col-6">
                      <img src={images.lotto} alt='logo' style={{ height: "12vh", width: "8vw" }} />
                    </div>
                    <div className="col-6 d-flex flex-column align-items-end" style={{ display: "flex", flexDirection: "column", alignItems: "end" }}>
                      <span className="fw-bold"><h3>EBET KENYA LIMITED</h3></span>
                      <span className="fw-bold"><h5>CHELEZO, KINDARUMA ROAD</h5></span>
                      <span className="fw-bold"><h5>KILIMANI</h5></span>
                      <span className="fw-bold"><h5><a href="http://">ebetkenyaltd@gmail.com</a></h5></span>
                    </div>

                  </div>
                </div>
                <h4 style={{ textAlign: "center", paddingTop: "15px" }}>Cash Payment Voucher</h4>
                <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                  <p className="custom-rectangle"><strong>{formattedDateString}</strong></p>
                </div>

                <div className="row data-details" style={{ marginTop: "-40px" }}>
                  <h4 style={{ marginLeft: "23px" }}>EXPENSE</h4>

                  <div className="col-md-12 col-sm-12">
                    <p className="custom-rectangle" style={{ height: "100%" }}>Amount: Ksh <strong>{value.amount ?? ''}</strong></p>
                  </div>
                  <div className="col-md-12 col-sm-12">
                    <p className="custom-rectangle" style={{ height: "100%" }}>Cash/Mpesa: <strong>{value.paymentType ?? ''} </strong></p>
                  </div>
                  <div className="col-md-12 col-sm-12">
                    <p className="custom-rectangle" style={{ height: "100%" }}>Description: <strong>{value.description ?? ''}</strong></p>
                  </div>
                  <div className="col-md-12 col-sm-12">
                    <p className="custom-rectangle" style={{ height: "100%" }}>Payee: <strong>{value.payee ?? ''}</strong></p>
                  </div>

                </div>
                <div className="row extend-expense">
                  <div className="col-md-12 col-12">
                    <p className="custom-rectangle" style={{ height: "100%" }}>Approved by : <strong>{value.approvedBy ?? ''}</strong></p>
                  </div>

                </div>
                <div className="row extend-expense" >
                  <div className="col-md-6 col-6">
                    <p className="custom-rectangle" style={{ height: "100%" }}>Paid by : <strong>{value.paidBy ?? ''}</strong></p>
                  </div>
                  <div className="col-md-6 col-6">
                    <p className="custom-rectangle" style={{ height: "100%" }}>Signature: </p>
                  </div>
                </div>
                <div className="row extend-expense" style={{ paddingBottom: "40px" }}>
                  <div className="col-md-6 col-6">
                    <p className="custom-rectangle" style={{ height: "100%" }}>Processed by : <strong>{value.userProcessor}</strong></p>
                  </div>
                  <div className="col-md-6 col-6">
                    <p className="custom-rectangle" style={{ height: "100%" }}>Signature: </p>
                  </div>
                </div>
              </BoxPrint>
            </div>

            <div className=' col-md-6 col-lg-6'  >

              {loading ? (
                <p>Loading...</p>
              ) : value.receiptPath ? (
                <BoxPrint>
                  <iframe
                    className='iframeClass'
                    src={`${baseUrl}${value.receiptPath}#toolbar=0`}
                    title='Receipt'
                    style={{ border: 'none', width: "550px", height: "730px", overflow: "hidden" }}
                  />
                </BoxPrint>
              ) : (
                <p>No PDF file available.</p>
              )}

            </div>


          </div>

        }
      </div>


    </>


  )
}

export default SingleReceipt