import React, { useContext, useEffect, useState } from 'react';
import './StylesScss/roles.scss';
import { images } from '../../constants';
import { Link } from 'react-router-dom';
import { LoginContext } from '../../App';
import { baseUrl } from '../../api/shared';
import axios from 'axios';

const ManageRoles = () => {


    const [loggedIn, setLoggedIn] = useContext(LoginContext);

    const [values, setValues] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() =>{
        getAllUsers();
    },[])


    const getAllUsers = () => {
        axios.get( baseUrl + '/api/UserRoles',{
            headers:{
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('token'),
            }
        }).then(response => {
            setValues(response.data);
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          })
          .finally(() => {
            setIsLoading(false); // Stop loading
          });
    }

    return (
        <div className="container-roles">

            {isLoading?(<div>Loading....</div>):(
                values.map(value =>(
                    <div className="cardProfile" key={value.userId}>
                    <div className="card__border">
                        <img
                            src={images.logoE}
                            alt="card image"
                            className="card__img"
                        />
                    </div>
    
                    <h3 className="card__name">{value.name}</h3>
                    <span className="card__profession">Roles</span>
    
                    <div className="card__social" id="card-social">
                        <Link to={value.userId} >
                            <div className="card__social-control">
                                <div className="card__social-toggle">
                                    <i className="bx  bx-user-plus"></i>
                                </div>
                                <span className="card__social-text">Assign Role</span>
                            </div>
                        </Link>
    
                    </div>
                </div>
                ))
            )}
        </div>
    )
}

export default ManageRoles