import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import { baseUrl } from '../../api/shared';

const Register = () => {

  const clearFormInputs = () => {
    nameChange("");
    usernamechange("");
    emailchange("");
    passwordchange("");
  };


  const [name, nameChange] = useState("");
  const [username, usernamechange] = useState("");
  const [password, passwordchange] = useState("");
  const [email, emailchange] = useState("");

  const navigate = useNavigate();

  

  const IsValidate = () => {

    let isproceed = true;
    
    let errormessage = 'Please enter the value in';

    if (username === null || username === '') {
      isproceed = false;
      errormessage += ' username'; // Add a space here
    }

    if (name === null || name === '') {
      isproceed = false;
      errormessage += ' name'; // Add a space here
    }

    if (email === null || email === '') {
      isproceed = false;
      errormessage += ' email'; // Add a space here
    }

    if (password === null || password === '') {
      isproceed = false;
      errormessage += ' password'; // Add a space here
    }

    if (!isproceed) {
      toast.error(errormessage);
    } else {
      if (/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email)) {
        // Validation for a valid email address
      } else {
        isproceed = false;
        toast.error('Please enter a valid email');
      }
    }

    return isproceed;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    let regobj = { name, username, email, password };

    if (IsValidate()) { // Call the IsValidate function
      const axiosInstance = axios.create({
        baseUrl,
        headers: { 'Content-Type': 'application/json' }
      });
      axiosInstance.post( baseUrl + "/api/account/register", regobj)
        .then((response) => {
          toast.success('Registration success', { duration: 5000, icon: '✅' });
          clearFormInputs();
          navigate("/");
        })
        .catch((msg) => {
          //msg.error("Error") 
        }); 
    }
  }

  return (
    <>
    <Toaster  position='top-right' />
      <form className="form-class" onSubmit={handleSubmit}>
        <h4>Create Account</h4>
        <input value={name} onChange={e => nameChange(e.target.value)} type="text" placeholder='Full Name' />
        <input  value={username} onChange={e => usernamechange(e.target.value)} type="text" placeholder='User Name' />
        <input value={email} onChange={e => emailchange(e.target.value)} type="email" placeholder='Email' />
        <input value={password} onChange={e => passwordchange(e.target.value)} type="password" placeholder='Password' />
        <button type='submit'>Sign Up</button>
      </form>
    </>
  )
}

export default Register;
