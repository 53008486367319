import React from "react";
import './authform.scss';

const AuthForm = props => {
    const className = {
        authForm: 'authForm',
        
    }

    return (
        <div className={Object.values(className).join(' ')} >
            {props.children}
        </div>
    )
}

export default AuthForm
