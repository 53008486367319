import React from 'react'
import './user-info.scss'

const UserInfo = ({ user }) => {

    const storedUser = localStorage.getItem("name");


    return (
        <div className='user-info'>
            <div className="user-info__img">
                <img src={user.img} alt="" />
            </div>
            <div className="user-info__name">
                <span>{storedUser}</span>
            </div>
        </div>
    )
}

export default UserInfo
