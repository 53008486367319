import React, { useContext, useEffect, useState } from 'react'
import { LoginContext } from '../../App'
import { useParams } from 'react-router-dom';
import { baseUrl } from '../../api/shared';
import { images } from '../../constants';
import './StylesScss/assign.scss';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';

const AssignRole = () => {

    const [loggedIn, setLoggedIn] = useContext(LoginContext);

    const { id } = useParams();

    const [value, setValue] = useState([]);

    const [userRoles, setUserRoles] = useState([]);

    

    useEffect(() => {
        getUser(id)
    }, [id])


    async function getUser(id) {
        try {
            const response = await axios.get(baseUrl + `/api/UserRoles/${id}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    }
                }
            );
            const { data } = response;
            setValue(data);
        } catch (error) {
            console.error(error);
        }
    }



    useEffect(() => {
        // Fetch user roles data based on the user id
        const fetchUserRoles = async () => {
          try {
            const response = await axios.get( baseUrl + `/api/UserRoles/manage/${id}`);
            setUserRoles(response.data);
          } catch (error) {
            console.error('Error fetching user roles:', error);
          }
        };
    
        fetchUserRoles();
      }, [id]);


      const handleRoleChange = (roleId) => {
        // Update the selected state of the role with the specified roleId
        const updatedRoles = userRoles.map((role) => {
          if (value.roles === null) {
            // If user roles are null, set all roles to selected
            return {
              ...role,
              selected: true,
            };
          } else {
            // Set selected to true only for the role with the specified roleId
            return {
              ...role,
              selected: role.roleId === roleId,
            };
          }
        });
        setUserRoles(updatedRoles);
      };
    

      const handleFormSubmit = async (e) => {
        e.preventDefault();

        try {
          // Filter out unselected roles
          const selectedRoles = userRoles.filter((role) => role.selected);
    
          // Make a POST request to update user roles
          await axios.post( baseUrl + `/api/UserRoles/manage/${id}`, selectedRoles);

          toast.success('User role updated successfully! ', { duration: 5000, icon: '✅' });
    
        } catch (error) {
          console.error('Error updating user roles:', error);
        }
      };



    return (
        <>

            <Toaster position="top-right" />

            <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center', paddingTop: "45px", paddingBottom: "-20px" }}>

                <div className="col-md-6 col-lg-6">
                    <div className="box1 boxz">
                        <div className="content">
                            <div className="image">
                                <img src={images.logoE} alt="profImg" />
                            </div>
                            <div className="level">
                                <p>{value.userName}</p>
                            </div>
                            <div className="text">
                                <p className="name">{value.name}</p>
                                <p className="job_title">{value.email}</p>
                            </div>

                            {value.roles ? (
                                <p>Roles: {value.roles.join(', ')}</p>
                            ) : (
                                <p>Loading roles...</p>
                            )}


                        </div>
                    </div>

                </div>



                <div className="col-md-6 col-lg-6">
                    <form method='post' onSubmit={handleFormSubmit}>
                        <div className="box1 boxz">
                            <div className="content">

                                <div className='check'>
                                    <div className="container-check">
                                        <div className="row">

                                            {userRoles.map((role) => (
                                                <div className="col-md-6 col-lg-6"  key={role.roleId}>
                                                    <label className="l-radio" id={role.roleName}>
                                                        <input
                                                            className="pesa"
                                                            type="radio"
                                                            tabIndex={role.roleId}
                                                            name="roleName"
                                                            value={role.roleId}
                                                            checked={role.selected}
                                                            onChange={() => handleRoleChange(role.roleId)}
                                                        />
                                                        <span>{role.roleName}</span>
                                                    </label>
                                                </div>
                                            ))}


                                        </div>
                                    </div>
                                </div>
                                <dl className="details">
                                    <div>
                                        <button type="submit" className="button">
                                            Save
                                        </button>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </form>

                </div>





            </div>
        </>

    )
}

export default AssignRole