import React from 'react'
import { FormReceipt } from '../components/form/FormReceipt'

const AddReceipt = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop:"45px", paddingBottom:"-20px" }} >
        <FormReceipt />
    </div>
  )
}

export default AddReceipt