import './assets/libs/boxicons-2.1.1/css/boxicons.min.css'
import './scss/App.scss'
import {  BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

import MainLayout from './layout/MainLayout'
import Dashboard from './Pages/Dashboard'
import AddReceipt from './Pages/AddReceipt'
import Authentication from './components/account/Authentication'
import AddLetter from './Pages/AddLetter'

import Receipts from './components/form/Receipts';
import EditReceipt from './components/form/EditReceipt';
import EditLetter from './components/form/EditLetter';
import Letter from './Pages/Letter';
import LettersComponent from './components/form/LetterList';
import { createContext, useContext, useEffect, useState } from 'react'
import { baseUrl } from './api/shared'
import axios from 'axios'
import RolesPage from './Pages/RolesPage'
import AssignRole from './components/form/AssignRole'
import ReceiptSingle from './Pages/ReceiptSingle'
import Recurrent from './components/form/Recurrent'
import ReportGeneration from './components/form/ReportGeneration'
import RecurrentList from './components/form/RecurrentList'
import RecurrentSingle from './components/form/RecurrentSingle'
import IndividualReport from './components/form/IndividualReport'
import EditRecurrent from './components/form/EditRecurrent'



export const LoginContext = createContext();

const ROLE = {
    "Basic": 2001,
    "Admin": 4040,
    "Super": 3045,
    "Moderator": 5160,
}

export const PrivateRoute = ({ element }) => {
    const [loggedIn] = useContext(LoginContext);

    // Check if the user is logged in
    const isAuthorized = loggedIn;

    return isAuthorized ? element : <Navigate to="/" />;
};


function App() {

    useEffect(() => {
        function refreshTokens() {
            if (localStorage.refresh) {
                const url = baseUrl + '/api/token/refresh';
                axios.post(url, {
                    refresh: localStorage.refresh
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        
                    }
                })
                    .then((response) => {
                        return response.data;
                    })
                    .then((data) => {
                        localStorage.access = data.access;
                        localStorage.refresh = data.refresh;
                        setLoggedIn(true);
                    })
                    .catch((error) => {
                        // Handle errors here
                        console.error('Axios error:', error);
                    });
            }
        }

        const minute = 1000 * 60;
        refreshTokens();
        setInterval(refreshTokens, minute * 3);

    }, [])


    const [loggedIn, setLoggedIn] = useState(
        localStorage.access ? true : false
    );

    function changeLoggedIn(value) {
        setLoggedIn(value);
        if (value === false) {
            localStorage.clear();
        }
    }

    return (
        <LoginContext.Provider value={[loggedIn, changeLoggedIn]}>
            <BrowserRouter>
            
                <Routes>
                    <Route path="/" element={<Authentication />} />

                    <Route path= "/main/" element={<PrivateRoute element={<MainLayout />} />} >
                        <Route index element={<Dashboard />} />
                        <Route path="postreceipt" element={<AddReceipt allowedRoles={[ROLE.Admin, ROLE.SuperAdmin]} />} />
                        <Route path="postletter" element={<AddLetter allowedRoles={[ROLE.Admin, ROLE.SuperAdmin]} />} />
                        <Route path="letters" element={<LettersComponent />} />
                        <Route path='recurrent' element={<Recurrent/>}/>
                        <Route path='report' element ={<ReportGeneration/>}/>
                        <Route path="receipts" element={<Receipts />} />
                        <Route path='recurrents' element={<RecurrentList/>}/>
                        <Route path='editRecurrent/:id' element={<EditRecurrent/>}/>
                        <Route path="editReceipt/:id" element={<EditReceipt allowedRoles={[ROLE.SuperAdmin]} />} />
                        <Route path='editletter/:id' element={<EditLetter allowedRoles={[ROLE.SuperAdmin]} />} />
                        <Route path="letter/:id" element={<Letter />} />
                        <Route path="receipt/:id" element={<ReceiptSingle />} />
                        <Route path="recurrentExpense/:id" element={<RecurrentSingle />} />
                        <Route path ="roles" element={<RolesPage/>}/>
                        <Route path="roles/:id" element={<AssignRole/>}/>
                        <Route path='singleReport' element={<IndividualReport/>}/>
                    </Route>

                </Routes>
            </BrowserRouter>
        </LoginContext.Provider>


    )
}

export default App;


