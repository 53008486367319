import React from 'react'
import SingleLetter from '../components/form/SingleLetter'

const Letter = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop:"45px", paddingBottom:"-20px" }}>
        <SingleLetter/>
    </div>
  )
}

export default Letter