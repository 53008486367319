import React, { useContext, useState } from "react"
import './StylesScss/form.scss';
import axios from "axios";

import { LoginContext } from "../../App";
import { Navigate } from "react-router-dom";
import { baseUrl } from "../../api/shared";

import toast, { Toaster } from 'react-hot-toast';
import BoxLetter from "../box/BoxLetter";


export const FormLetter = () => {




    const storedUser = localStorage.getItem("name");
    const userID = localStorage.getItem("userId");

    const [values, setValues] = useState({
        description: "",
        userId: "",
        userProcessor: ""
    });

    const [selectedFile, setSelectedFile] = useState(null);


    const resetForm = () => {
        setValues({
            description: "",
        });
        setSelectedFile(null);
        document.getElementById('file-uploader').value = "";
    }

    const handleFileChange = e => {
        const letterFile = e.target.files[0];
        setSelectedFile(letterFile);
    }

    const handleInputChange = e => {
        const { name, value } = e.target;
        setValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    }

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            const formData = new FormData();

            formData.append('description', values.description);
            formData.append('userId', userID);
            formData.append('userProcessor', storedUser);

            console.log(formData);
            if (selectedFile) {
                formData.append('letterFile', selectedFile);
            }

            const response = await axios.post(baseUrl + `/api/letters`, formData, {
                headers: {
                    "Content-Type": 'multipart/form-data',
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                },

            });

            resetForm();


            toast.success('Letter uploaded successfully! ', { duration: 3000, icon: '✅' });

        } catch (error) {
            console.log('An error occurred:', error);
        }
    }

    const [loggedIn] = useContext(LoginContext);

    if (!loggedIn) {
        // Redirect to login if not logged in
        return <Navigate to="/" />;
    }

    return (
        <>


            <Toaster position="top-right" />
            <BoxLetter>
                <div className="expense-form"   >
                    <form className="expense-form__form" onSubmit={handleSubmit} method="post">
                        <div className="expense-form__section " >
                            <h4>Letter File</h4>
                            <input
                                id="file-uploader"
                                type="file"
                                name="receiptFile"
                                accept="*"
                                className="expense-form__filePdf "
                                placeholder="Upload Receipt"
                                onChange={handleFileChange}
                                style={{ height: "100%", width: "100%" }}
                            />

                        </div>

                        <div className="expense-form__section ">
                            <h4>Description</h4>
                            <textarea
                                type="text"
                                name="description"
                                value={values.description}
                                onChange={handleInputChange}
                                style={{ width: "300px", height: "150px" }}
                                placeholder="Description:"
                            />
                        </div>

                        <div className="expense-form__field" style={{ display: "none" }} >
                            <input
                                type="text"
                                name="userProcessor"
                                value={storedUser}
                                readOnly
                                className="expense-form__input"
                                onChange={handleInputChange}
                            />
                            <input
                                type="text"
                                readOnly
                                name="userId"
                                className="expense-form__input"
                                value={userID}
                                onChange={handleInputChange}
                            />
                        </div>

                        <dl className="details">
                            <div>
                                <button className="button">
                                    Save
                                </button>
                            </div>
                        </dl>
                    </form>
                </div>
            </BoxLetter>

        </>


    )
}


