import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { baseUrl } from '../../api/shared';

const IndividualReport = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [values, setValues] = useState([]);
  const [error, setError] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const pageSize = 12;

  const [searchQuery, setSearchQuery] = useState('');
  const [filteredValues, setFilteredValues] = useState([]);


  useEffect(() => {
    // Fetch data when both start and end dates are provided
    if (searchQuery) {
      getDataReport();
    }
  }, [searchQuery]);

  const getDataReport = async () => {
    try {
      let apiUrl = baseUrl + `/api/Payments/similarPayment?pageNumber=${pageNumber}&pageSize=${pageSize}`;

      if (searchQuery) {
        const searchParams = new URLSearchParams();
        if (searchQuery) searchParams.append('searchQuery', searchQuery);
        apiUrl += `&${searchParams.toString()}`;
      }

      setIsLoading(true); // Set loading to true before making the request

      const response = await axios.get(apiUrl, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        }
      });

      setFilteredValues(response.data);
      setIsLoading(false); // Set loading to false after successful data fetching
      setError(null);
    } catch (error) {
      setIsLoading(false); // Set loading to false in case of an error
      setError('Error fetching data');
    }
  };



  useEffect(() => {
    // Filter data based on selectedOption, startDate, endDate
    const filteredData = values.filter(value => {
      return (
        value.expenseType &&  // Check for null or undefined
        value.expenseType.includes(searchQuery) ||
        value.paymentType.includes(searchQuery)
      );
    });
    setFilteredValues(filteredData);
  }, [searchQuery, values]);


  const handleSearchInput = (e) => {
    setSearchQuery(e.target.value);
  };

  let totalAmount = 0;

  const handlePrint = () => {
    window.print();
  }

  const formatReadableDate = (isoDate) => {
    const date = new Date(isoDate);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  };

  return (
    <>
      <div className="row" style={{ paddingTop: "15px" }}>
        <div className="form-search col-md-6 col-6 col-lg-6" style={{ marginLeft: "100px" }} >
          <input
            type="text"
            className="select-box"
            placeholder="Search"
            style={{ width: '150px', borderRadius: '10px', height: '30px', padding: "5px" }}
            value={searchQuery}
            onChange={handleSearchInput}
          />
        </div>
        <div className="col-md-6 col-6 col-lg-6 print-btn" style={{ marginLeft: "500px" }}>
          <div className="printer" style={{ float: "right" }} onClick={handlePrint}>
            <div className="paper">
              <svg viewBox="0 0 8 8" className="svg">
                <path fill="#0077FF" d="M6.28951 1.3867C6.91292 0.809799 7.00842 0 7.00842 0C7.00842 0 6.45246 0.602112 5.54326 0.602112C4.82505 0.602112 4.27655 0.596787 4.07703 0.595012L3.99644 0.594302C1.94904 0.594302 0.290039 2.25224 0.290039 4.29715C0.290039 6.34206 1.94975 8 3.99644 8C6.04312 8 7.70284 6.34206 7.70284 4.29715C7.70347 3.73662 7.57647 3.18331 7.33147 2.67916C7.08647 2.17502 6.7299 1.73327 6.2888 1.38741L6.28951 1.3867ZM3.99679 6.532C2.76133 6.532 1.75875 5.53084 1.75875 4.29609C1.75875 3.06133 2.76097 2.06018 3.99679 2.06018C4.06423 2.06014 4.13163 2.06311 4.1988 2.06905L4.2414 2.07367C4.25028 2.07438 4.26057 2.0758 4.27406 2.07651C4.81533 2.1436 5.31342 2.40616 5.67465 2.81479C6.03589 3.22342 6.23536 3.74997 6.23554 4.29538C6.23554 5.53084 5.23439 6.532 3.9975 6.532H3.99679Z"></path>
                <path fill="#0055BB" d="M6.756 1.82386C6.19293 2.09 5.58359 2.24445 4.96173 2.27864C4.74513 2.17453 4.51296 2.10653 4.27441 2.07734C4.4718 2.09225 5.16906 2.07947 5.90892 1.66374C6.04642 1.58672 6.1743 1.49364 6.28986 1.38647C6.45751 1.51849 6.61346 1.6647 6.756 1.8235V1.82386Z"></path>
              </svg>
            </div>
            <div className="dot"></div>
            <div className="output">
              <div className="paper-out"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="report receipt-toprint" style={{ padding: "5px" }}>
        <table className="col-md-9 col-lg-8 table-fill " >
          <thead>
            <tr>
              <th className="text-left col-md-4">Expense</th>
              <th className="text-left col-md-4">Total Amount</th>
              <th className="text-left col-md-4">Date</th>
            </tr>
          </thead>
          <tbody className="table-hover">
            {isLoading ? (
              <tr>
                <td className="text-left col-md-4">Loading....</td>
                <td className="text-left col-md-4" >Loading....</td>
                <td className="text-left col-md-4" >Loading....</td>
              </tr>
            ) : (
              filteredValues.map((value, index) => {
                totalAmount += value.amount;
                return (
                  <tr key={index}>
                    <td className="text-left col-md-4">{value.expenseType}</td>
                    <td className="text-left col-md-4">{value.amount}</td>
                    <td className="text-left col-md-4">{formatReadableDate(value.creationDate)}</td>
                  </tr>
                );
              })
            )}
            <tr>
              <td className=" col-md-4">Total</td>
              <td className=" col-md-8" colSpan="2" style={{ display:"flex", justifyContent:"end", margin:"0 auto"}}>{totalAmount}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}

export default IndividualReport