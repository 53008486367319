import React from 'react'
import ManageRoles from '../components/form/ManageRoles'

const RolesPage = () => {
  return (
    <div  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop:"45px", paddingBottom:"-20px" }}>
      <ManageRoles/>
    </div>
  )
}

export default RolesPage