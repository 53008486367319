import React, { useContext } from 'react';
import { useState } from 'react';
import BoxForm from '../box/BoxForm';
import axios from 'axios';

import './StylesScss/form.scss';
import { LoginContext } from '../../App';
import { baseUrl } from '../../api/shared';
import toast, { Toaster } from 'react-hot-toast';




export const FormReceipt = () => {

    const [loggedIn, setLoggedIn] = useContext(LoginContext);

    const storedUser = localStorage.getItem("name");
    const userID = localStorage.getItem("userId");

    const [values, setValues] = useState({
        receivedFrom: "Ebet Kenya LTD",
        amount: "",
        description: "",
        payee: "",
        paymentType: "",
        approvedBy: "",
        paidBy: "",
        userId: "",
        userProcessor: "",
        expenseType: ""
    });


    const [selectedFile, setSelectedFile] = useState(null);

    const handleInputChange = e => {
        const { name, value } = e.target;
        setValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    }



    const [errors, setErrors] = useState({
        amount: '',
        description: '',
        payee: '',
        paymentType: "",
        approvedBy: '',
        paidBy: '',
    });


    const allItems = [
        'CSR',
        'Furniture',
        'Show',
        'Electronics',
        'Stationary',
        'Breakfast',
        'Uniform',
        'Bus Fuel',
        'Bus Service',
        'Internet',
        'Electricity',
    ];

    const validateForm = () => {
        const newErrors = {};

        // Check each field for emptiness
        if (!values.amount) {
            newErrors.amount = 'Amount is required';
        } else if (parseFloat(values.amount) < 0) {
            newErrors.amount = 'Amount cannot be negative';
        }

        if (!values.description) {
            newErrors.description = 'Description is required';
        }
        if (!values.payee) {
            newErrors.payee = 'Payee is required';
        }
        if (!values.paymentType) {
            newErrors.paymentType = 'Payment Type is required';
        }
        if (!values.approvedBy) {
            newErrors.approvedBy = 'Approved By is required';
        }
        if (!values.paidBy) {
            newErrors.paidBy = 'Paid By is required';
        }

        setErrors(newErrors);

        // Check if there are any validation errors
        return Object.keys(newErrors).length === 0;
    }

    const resetForm = () => {
        setValues({
            receivedFrom: "Ebet Kenya LTD",
            amount: "",
            description: "",
            payee: "",
            paymentType: "",
            approvedBy: "",
            paidBy: "",
            expenseType: ""
        });
        setSelectedFile(null);
        document.getElementById('file-uploader').value = "";
    }

    const handleFileChange = e => {
        const receiptFile = e.target.files[0];
        setSelectedFile(receiptFile);
    }

    const handleSubmit = async e => {
        e.preventDefault();

        const isValid = validateForm();

        if (isValid) {
            try {
                const formData = new FormData();

                formData.append('receivedFrom', values.receivedFrom);
                formData.append('paymentType', values.paymentType);
                formData.append('amount', values.amount);
                formData.append('description', values.description);
                formData.append('payee', values.payee);
                formData.append('approvedBy', values.approvedBy);
                formData.append('paidBy', values.paidBy);
                formData.append('userId', userID);
                formData.append('userProcessor', storedUser);
                formData.append('expenseType', values.expenseType);


                console.log(formData);

                if (selectedFile) {
                    formData.append('receiptFile', selectedFile);
                }

                const response = await axios.post(baseUrl + `/api/payments/`, formData, {
                    headers: {
                        "Content-Type": 'multipart/form-data',
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    },

                });

                resetForm();

                toast.success('Receipt details recorded successfully! ', { duration: 3000, icon: '✅' });


            } catch (error) {
                console.log('An error occurred:', error);
            }
        }

    }

    return (
        <>
            <Toaster position="top-right" />
            <BoxForm >
                <div className="expense-form">
                    <form className="expense-form__form" onSubmit={handleSubmit} method="post">
                        <div className="expense-form__section">
                            <h4>SOURCE OF FINANCE</h4>
                            <div className="expense-form__field">
                                <input
                                    type="text"
                                    name="receivedFrom"
                                    value={values.receivedFrom}
                                    readOnly
                                    className="expense-form__input"
                                    placeholder="Received from:"
                                    onChange={handleInputChange}
                                />
                            </div>

                            <div className='check'>
                                <label className="l-radio col-md-2">
                                    <input className='pesa' type="radio" id="f-option" tabIndex="1"
                                        name="paymentType"
                                        value="Mpesa"
                                        checked={values.paymentType === "Mpesa"}
                                        onChange={handleInputChange}
                                    />
                                    <span>Mpesa</span>
                                </label>
                                <label className="l-radio col-md-2">
                                    <input className='pesa' type="radio" id="s-option" tabIndex="2"
                                        name="paymentType"
                                        value="Cash"
                                        checked={values.paymentType === "Cash"}
                                        onChange={handleInputChange}
                                    />
                                    <span>Cash</span>
                                </label>
                                <label className="l-radio col-md-2">
                                    <input className='pesa' type="radio" id="d-option" tabIndex="3"
                                        name="paymentType"
                                        value="Accounts"
                                        checked={values.paymentType === "Accounts"}
                                        onChange={handleInputChange}
                                    />
                                    <span>Acc</span>
                                </label>
                                <label className="l-radio col-md-2">
                                    <input className='pesa' type="radio" id="d-option" tabIndex="4"
                                        name="paymentType"
                                        value="School Fee"
                                        checked={values.paymentType === "School Fee"}
                                        onChange={handleInputChange}
                                    />
                                    <span>Fee</span>
                                </label>

                            </div>

                            <div className="expense-form__input-group">
                                <input
                                    type="number"
                                    name="amount"
                                    value={values.amount}
                                    className="expense-form__input"
                                    placeholder="Amount"
                                    onChange={handleInputChange}
                                />
                            </div>

                            <div className="expense-form__input-group" style={{ display: "flex", flexDirection: "row" }}>


                            <select className=" col-6"
                                    style={{ height: "45px" }}
                                    name='expenseType'
                                    value={values.expenseType}
                                    onChange={handleInputChange}
                                    
                                   >
                                    {allItems
                                        .map((item, index) => (
                                            <option key={index} className="t-dropdown-item">
                                                {item}
                                            </option>
                                        ))}
                                </select>

                                <input
                                    type="text"
                                    className="expense-form__input col-6"
                                    name='expenseType'
                                    value={values.expenseType}
                                    onChange={handleInputChange}
                                    placeholder="Expense Type"
                                />

                            </div>



                            <div className="expense-form__field" style={{ display: "none" }} >



                                <input
                                    type="text"
                                    name="userProcessor"
                                    value={storedUser}
                                    readOnly
                                    className="expense-form__input"
                                    onChange={handleInputChange}
                                />

                                <input
                                    type="text"
                                    readOnly
                                    name="userId"
                                    className="expense-form__input"
                                    value={userID}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="expense-form__section">
                            <h4>EXPENSE</h4>
                            <div className="row">
                                <div className="expense-form__field col-6" >
                                    <textarea
                                        type="text"
                                        name="description"
                                        value={values.description}
                                        className="expense-form__input"
                                        placeholder="Description:"
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="expense-form__field col-6">
                                    <textarea
                                        type="text"
                                        name="payee"
                                        value={values.payee}
                                        className="expense-form__input"
                                        placeholder="Payee:"
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>

                        </div>
                        <div className="expense-form__section row">
                            <div className="expense-form__field col-6">
                                <textarea
                                    type="text"
                                    name="approvedBy"
                                    value={values.approvedBy}
                                    className="expense-form__input"
                                    placeholder="Approved By:"
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="expense-form__field col-6">
                                <textarea
                                    type="text"
                                    name="paidBy"
                                    value={values.paidBy}
                                    className="expense-form__input"
                                    placeholder="Paid By:"
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>

                        <h4>Receipt</h4>
                        <input
                            id="file-uploader"
                            type="file"
                            name="receiptFile"
                            accept="*"
                            className="expense-form__filePdf col-md-8 col-sm-12"
                            placeholder="Upload Receipt"
                            onChange={handleFileChange}

                        />

                        <dl className="details">
                            <div>
                                <button className="button">
                                    Save
                                </button>
                            </div>
                        </dl>

                    </form>
                </div>

            </BoxForm>

        </>
    );

}
