import React, { useContext, useEffect, useState } from "react"
import './StylesScss/form.scss';
import BoxLetter from "../box/BoxLetter";
import axios from "axios";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import { LoginContext } from "../../App";
import { baseUrl } from "../../api/shared";




const EditLetter = () => {

    const navigate = useNavigate();

    const storedUser = localStorage.getItem("name");
    const userID = localStorage.getItem("userId");

    const [values, setValues] = useState({
        description: "",
        letterFile:"",
        letterFilePath:"",
        userId: userID,
        userProcessor: storedUser
    });

    const [selectedFile, setSelectedFile] = useState(null);

    const { id } = useParams();




    useEffect(() => {
        getLetter(id);
    }, [id]);


    async function getLetter(receiptId) {
        try {
            const response = await axios.get(`${baseUrl}/api/letters/${receiptId}`,{
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: 'Bearer ' + localStorage.getItem('token'),
                }});
            const { data } = response;
            console.log(data);
            setValues({
                description: data.description,
                letterFilePath: data.letterFilePath,
            });
        } catch (error) {
            console.error(error);
        }
    }


    const handleInputChange = e => {
        const { name, value } = e.target;
        setValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    }

    
    const handleFileChange = e => {
        const letterFile = e.target.files[0];
        setSelectedFile(letterFile);
    }

   


    const handleUpdate = async (formData) => {
        try {
            await axios.put(`${baseUrl}/api/letters/${id}`, formData,{
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                },
            });

            toast.error('Form Updated successfully', { duration: 5000, icon: '✅' });

        } catch (error) {
            console.log('An error occurred:', error);
        }

        
    }

    const handleDelete = async () => {
        try {
            await axios.delete(`${baseUrl}/api/letters/${id}`,{
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                },
            });
            // Redirect to the home page after successful delete
            navigate('/main');

            toast.error('Form deleted ', { duration: 5000, icon: '🗑️' });
        } catch (error) {
            console.log('An error occurred:', error);
        }

        
    }



    const [loggedIn] = useContext(LoginContext);

    if (!loggedIn) {
        // Redirect to login if not logged in
        return <Navigate to="/" />;
    }

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append('description', values.description);
            formData.append('letterFilePath', values.letterFilePath);
            formData.append("userId", userID);
            formData.append("userProcessor",storedUser);
            if (selectedFile) {
                formData.append('letterFile', selectedFile);
            }else {
                formData.append('letterFilePath', values.letterFilePath);
            }

           await handleUpdate(formData);

        } catch (error) {
            console.log('An error occurred:', error);
        }
    }

    return (
        <>
            <Toaster position="top-right" />
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: "45px", paddingBottom: "-20px" }}>
                <BoxLetter>
                    <div className="expense-form"   >
                        <form className="expense-form__form" onSubmit={handleSubmit} method="post">
                            <div className="expense-form__section " >
                                <h4>Letter File</h4>
                                <input
                                    id="file-uploader"
                                    type="file"
                                    name="letterFile"
                                    accept="*"
                                    className="expense-form__filePdf "
                                    placeholder="Upload Receipt"
                                    onChange={handleFileChange}
                                    style={{ height: "100%", width: "100%" }}
                                />

                            </div>

                            <div className="expense-form__section ">
                                <h4>Description</h4>
                                <textarea
                                    type="text"
                                    name="description"
                                    value={values.description}
                                    onChange={handleInputChange}
                                    style={{ width: "300px", height: "150px" }}
                                />
                            </div>

                            <div className="expense-form__field"  style={{ display: "none" }} >
                                    <input
                                        type="text"
                                        name="userProcessor"
                                        value={values.userProcessor}
                                        readOnly
                                        className="expense-form__input"
                                        onChange={handleInputChange}
                                    />

                                    <input
                                        type="text"
                                        readOnly
                                        name="userId"
                                        className="expense-form__input"
                                        value={values.userId}
                                        onChange={handleInputChange}
                                    />
                                </div>

                            <div className="expense-form__input-group filePath">
                                    <input
                                        type="text"
                                        name="letterFilePath"
                                        value={values.letterFilePath}
                                        className="expense-form__input"
                                        placeholder="receiptPath"
                                        onChange={handleInputChange}
                                    />
                                </div>
                            <div className="row">
                                <div className="col-6">
                                    <dl className="details">
                                        <div>
                                            <button className="button">
                                                Update
                                            </button>
                                        </div>
                                    </dl>
                                </div>
                                <div className="col-6">
                                    <dl className="details">
                                        <div>
                                            <button type="button" className="button" onClick={handleDelete}>
                                                Delete
                                            </button>
                                        </div>
                                    </dl>
                                </div>
                            </div>
                        </form>
                    </div>
                </BoxLetter>
            </div>
        </>


    )
}

export default EditLetter