import React, { useState, useEffect } from 'react';
import './summary-box.scss';
import Box from '../box/Box';
import { Link } from 'react-router-dom';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js'
import axios from 'axios';
import { baseUrl } from '../../api/shared';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)

const SummaryBox = () => {
    const [values, setValues] = useState([]);

    useEffect(() => {
        getTopData();
    }, []);

    const getTopData = () => {
        axios.get( baseUrl +'/api/payments/stats/')
            .then(response => {
                setValues(response.data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    const summary = [
        {
            icon: <i className='bx bx-receipt' ></i>,
        },
        {
            icon: <i className='bx bx-envelope'></i>,
        },
        

    ]

    return (
        <>
            {values.map((value,index) => (
                <div className='col-6 col-md-4 mb' key={value.id}>
                    <Box>
                        <Link to={value.nameOfStat}>
                            <div className='summary-box'>
                                <div className="summary-box__info">
                                    <div className="summary-box__info__title">
                                        <div>{value.nameOfStat}</div>
                                        <span>Total {value.nameOfStat}</span>
                                    </div>
                                    <div className="summary-box__info__value">
                                        {value.statCount}
                                    </div>
                                </div>
                                <div className="summary-box__chart">
                                    <div className='summary-box__chart__icon'>
                                       {summary[index].icon}
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </Box>
                </div>
            ))}
        </>
    )
}

export default SummaryBox;

export const SummaryBoxSpecial = ({ item }) => {


    const [expenseData, setExpenseData] = useState([]);

    useEffect(() => {
        // Fetch data from the API endpoint using Axios
        axios.get( baseUrl + '/api/payments/totalPayment',{
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('token'),
              }
        })
            .then(response => setExpenseData(response.data))
            .catch(error => console.error('Error fetching data:', error));
    }, []);


    const monthlyTotals = {};

    expenseData.forEach(expense => {
        const month = new Date(expense.creationDate).toLocaleString('en-US', { month: 'long', year: 'numeric' });
    
        if (!monthlyTotals[month]) {
          monthlyTotals[month] = 0;
        }
    
        monthlyTotals[month] += expense.amount;

      });


      

    const totalExpense = Object.values(monthlyTotals).reduce((acc, amount) => acc + amount, 0);


    return (
        <>
            <div className="col-6 col-md-4">
                <Box purple >
                    <div className="summary-box-special">
                        <div className="summary-box-special__title">
                            {item.title}
                        </div>
                        <div className="summary-box-special__value" >
                            Ksh {totalExpense}
                        </div>

                    </div>
                </Box>
            </div>

        </>

    )
}