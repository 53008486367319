import React from "react";
import './boxprint.scss';

const BoxPrint = props => {
    const className = {
        boxPrint: 'boxPrint', 
    }

    return (
        <div className={Object.values(className).join(' ')} >
            {props.children}
        </div>
    )
}

export default BoxPrint