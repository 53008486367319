import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import { LoginContext } from '../../App';
import { baseUrl } from '../../api/shared';


const Login = () => {
    const [loggedIn, setLoggedIn] = useContext(LoginContext);
    const [email, emailChange] = useState('');
    const [password, passwordLogin] = useState('');
    const location = useLocation();
    const usenavigate = useNavigate();

    useEffect(() => {
        // Check for existing session on component mount
        const token = localStorage.getItem('token');
        if (token) {
            setLoggedIn(true);

            const storedPage = sessionStorage.getItem('current_page');
            if (storedPage) {
                // If a stored page is found, navigate to it
                usenavigate(storedPage);
            } else {
                // Navigate to the default page if no stored page is found
                usenavigate(
                    location?.state?.previousUrl
                        ? location.state.previousUrl
                        : '/main'
                );
            }
        }
    }, [setLoggedIn, usenavigate, location]);


    function login(e) {
        e.preventDefault();

        if (validate()) {
            axios
                .post(
                    baseUrl + '/api/account/login',
                    JSON.stringify({ email, password }),
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        withCredentials: true,
                    }
                )
                .then((response) => {
                    const data = response.data;

                    if (
                        data.token &&
                        data.refreshToken &&
                        data.statusCode &&
                        data.username &&
                        data.name &&
                        data.userId
                    ) {
                        localStorage.setItem('token', data.token);
                        localStorage.setItem('refreshToken', data.refreshToken);
                        localStorage.setItem('statusCode', data.statusCode);
                        localStorage.setItem('username', data.username);
                        localStorage.setItem('name', data.name);
                        localStorage.setItem('userId', data.userId)

                        setLoggedIn(true);

                        const currentPage = location.pathname;
                        // Store the current page in sessionStorage
                        sessionStorage.setItem('current_page', currentPage);

                        usenavigate(
                            location?.state?.previousUrl
                                ? location.state.previousUrl
                                : '/main'
                        );
                        toast.success('Logged', { duration: 5000, icon: '✅' });
                    } else {
                        toast.error('Invalid email or password');
                    }
                })
                .catch((error) => {
                    // Handle errors here
                    toast.error('Axios error:', error ,{ duration: 5000, icon: '✅' });
                });
        }
    }

    const validate = () => {
        let result = true;

        if (email === '' || email === null) {
            result = false;
            toast.error('Please Enter Username');
        }
        if (password === '' || password === null) {
            result = false;
            toast.error('Please Enter Password');
        }
        return result;
    };

    return (
        <>
            <Toaster position="top-right" />
            <form className="form-class" onSubmit={login}>
                <h1>Sign In</h1>
                <input
                    value={email}
                    onChange={(e) => emailChange(e.target.value)}
                    type="email"
                    placeholder="Email"
                />
                <input
                    value={password}
                    onChange={(e) => passwordLogin(e.target.value)}
                    type="password"
                    placeholder="Password"
                />
                <button type="submit">Sign In</button>
            </form>
        </>
    );
};

export default Login;
