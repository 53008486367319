import React, { useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2';
import Box from '../components/box/Box';
import DashboardWrapper, { DashboardWrapperMain } from '../components/dashboard-wrapper/DashboardWrapper';
import SummaryBox, { SummaryBoxSpecial } from '../components/summary-box/SummaryBox';
import { colors, data } from '../constants';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js'
import { baseUrl } from '../api/shared';
import axios from 'axios';


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend
)

const Dashboard = () => {



    return (

        <DashboardWrapper>
            <DashboardWrapperMain>

                <div className="col-md-12" style={{ display: "flex" }}>
                    <SummaryBox />
                    <SummaryBoxSpecial item={data.revenueSummary} />
                </div>

                <div className="row">
                    <div className="col-12">
                        <Box>
                            <RevenueByMonthsChart />
                        </Box>
                    </div>
                </div>
            </DashboardWrapperMain>
        </DashboardWrapper>

    )
}

export default Dashboard

const RevenueByMonthsChart = () => {

    const [expenseData, setExpenseData] = useState([]);

    useEffect(() => {
        // Fetch data from the API endpoint using Axios
        axios.get(baseUrl + '/api/payments/totalPayment',{
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('token'),
              }
        })
            .then(response => setExpenseData(response.data))
            .catch(error => console.error('Error fetching data:', error));
    }, []);


    const monthlyTotals = {};

    expenseData.forEach(expense => {
        const month = new Date(expense.creationDate).toLocaleString('en-US', { month: 'long', year: 'numeric' });
    
        if (!monthlyTotals[month]) {
          monthlyTotals[month] = 0;
        }
    
        monthlyTotals[month] += expense.amount;

      });


    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            xAxes: {
                grid: {
                    display: false,
                    drawBorder: false
                }
            },
            yAxes: {
                grid: {
                    display: false,
                    drawBorder: false
                }
            }
        },
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: false
            }
        },
        elements: {
            bar: {
                backgroundColor: colors.orange,
                borderRadius: 20,
                borderSkipped: 'bottom'
            }
        }
    }

    const chartData = {
        labels: Object.keys(monthlyTotals),
        datasets: [
            {
                label: 'Expense',
                data:  Object.values(monthlyTotals)
            }
        ]
    }
    return (
        <>
            <div className="title mb">
                Expense by months
            </div>
            <div>
                <Bar options={chartOptions} data={chartData} height={`300px`} />
            </div>
        </>
    )
}